import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'http://127.0.0.1:9131', // 你的 API 基础 URL
    headers: {
        'Content-Type': 'application/json'
    }
});

export const login = (data) => {
    return apiClient.post('/user/codeLogin', data);
};
