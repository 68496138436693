<template>
  <div class="login-container">
    <div class="login-box">
      <div class="back-header">
        <div class="back-btn" @click="handleBack">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M19 12H5M12 19l-7-7 7-7"/>
          </svg>
          <span>返回</span>
        </div>
      </div>

      <h2>手机验证码登录</h2>

      <!-- 手机号输入 -->
      <div class="input-group">
        <input
            type="text"
            v-model="phone"
            placeholder="请输入手机号"
            maxlength="11"
        >
      </div>

      <!-- 验证码输入框和发送按钮 -->
      <div class="verify-group">
        <input
            type="text"
            v-model="verifyCode"
            placeholder="请输入验证码"
            maxlength="6"
        >
        <button
            :disabled="!canSendCode || countdown > 0"
            @click="sendVerifyCode"
            class="send-btn"
        >
          {{ countdown > 0 ? `${countdown}秒后重发` : '发送验证码' }}
        </button>
      </div>

      <!-- 登录按钮 -->
      <button
          class="login-btn"
          :disabled="!canLogin"
          @click="handleLogin"
      >
        登录
      </button>
    </div>
  </div>
</template>

<script>
import { login } from '@/api/api'; // 导入封装的 API 方法
export default {
  name: 'PhoneLogin',
  data() {
    return {
      phone: '',
      verifyCode: '',
      countdown: 0,
      timer: null
    }
  },
  computed: {
    // 是否可以发送验证码
    canSendCode() {
      return /^1[3-9]\d{9}$/.test(this.phone)
    },
    // 是否可以登录
    canLogin() {
      return this.canSendCode && this.verifyCode.length === 6
    }
  },
  methods: {
    handleBack(){
      this.$router.push('/');
    },
    // 发送验证码
    sendVerifyCode(message) {
      if (!this.canSendCode) return
      try {
        // 这里添加发送验证码的接口调用
        let phone= this.phone;
        this.$axios.post(this.$httpUrl + "/user/phoneCode", {
          phone
        }).then(res => res.data).then(res => {
          if (res.code == 200) {
            alert("发送成功,验证码为"+res.data);

          } else if (res.code == 400) {
            alert("发送失败,"+res.data);
          }
          else {
            alert("发送失败，请重试！");
          }
        })
        // await this.$api.sendCode(this.phone)

        // 开始倒计时
        this.countdown = 60
        this.startCountdown()

      } catch (error) {
        console.error('发送验证码失败:', error)
      }
    },

    // 倒计时
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
    },

    // 登录
    async handleLogin(message) {
      if (!this.canLogin) return

      try {
        //这里添加登录接口调用
        this.$axios.post(this.$httpUrl + "/user/codeLogin", {
          phone:this.phone,
          code:this.verifyCode
        }).then(res => res.data).then(res => {
         console.log(res)
          if (res.code == 200) {
            alert('登录成功');
            // 登录成功后的处理
            //存储
            sessionStorage.setItem(
                "CurUser",
                JSON.stringify(res.data.user)
            )
            this.$store.commit("setMenu", res.data.menu);
            //跳转到主页
            this.$router.replace("/Index");
          }
          else {
            alert("登录失败,")
          }
        })


      } catch (error) {
        console.error('登录失败:', error)
      }
    }
  },
  // 组件销毁时清除定时器
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.login-box {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.input-group,
.verify-group {
  margin-bottom: 20px;
}

input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.verify-group {
  display: flex;
  gap: 10px;
}

.verify-group input {
  flex: 1;
}

.send-btn {
  width: 120px;
  background: #fff;
  border: 1px solid #1890ff;
  color: #1890ff;
  padding: 0 15px;
  border-radius: 4px;
  cursor: pointer;
}

.send-btn:disabled {
  border-color: #d9d9d9;
  color: #d9d9d9;
  cursor: not-allowed;
}

.login-btn {
  width: 100%;
  padding: 12px;
  background: #1890ff;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.login-btn:disabled {
  background: #d9d9d9;
  cursor: not-allowed;
}

input:focus {
  outline: none;
  border-color: #1890ff;
}
</style>
